/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"

import Layout from "../components/Layout"

const TermsPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>Terms of service</Heading>

          <Text variant="subtitle">1. Terms</Text>
          <Text variant="paragraph">
            By accessing the website at{" "}
            <a href="http://www.adammonster.com/">http://www.adammonster.com</a>
            , you are agreeing to be bound by these terms of service, all
            applicable laws, and regulations, and agree that you are responsible
            for compliance with any applicable local laws. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this site. The materials contained in this website are protected by
            applicable copyright and trademark law.
          </Text>

          <Text variant="subtitle">2. Use License</Text>
          <Text variant="paragraph" as="ol">
            <Text variant="paragraph" as="li">
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Adam Monster&apos;s website
              for personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license, you may not:
              <Text variant="paragraph" as="ol">
                <Text variant="paragraph" as="li">
                  modify or copy the materials;
                </Text>
                <Text variant="paragraph" as="li">
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </Text>
                <Text variant="paragraph" as="li">
                  attempt to decompile or reverse engineer any software
                  contained on Adam Monster&apos;s website;
                </Text>
                <Text variant="paragraph" as="li">
                  remove any copyright or other proprietary notations from the
                  materials; or
                </Text>
                <Text variant="paragraph" as="li">
                  transfer the materials to another person or
                  &ldquo;mirror&rdquo; the materials on any other server.
                </Text>
              </Text>
            </Text>
            <Text variant="paragraph" as="li">
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Adam Monster at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </Text>
          </Text>

          <Text variant="subtitle">3. Disclaimer</Text>
          <Text variant="paragraph" as="ol">
            <Text variant="paragraph" as="li">
              The materials on Adam Monster&apos;s website are provided on an
              &apos;as is&apos; basis. Adam Monster makes no warranties,
              expressed or implied, and hereby disclaims and negates all other
              warranties including, without limitation, implied warranties or
              conditions of merchantability, fitness for a particular purpose,
              or non-infringement of intellectual property or other violation of
              rights.
            </Text>
            <Text variant="paragraph" as="li">
              Further, Adam Monster does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site.
            </Text>
          </Text>

          <Text variant="subtitle">4. Limitations</Text>
          <Text variant="paragraph">
            In no event shall Adam Monster or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on Adam Monster&apos;s website, even
            if Adam Monster or an Adam Monster authorized representative has
            been notified orally or in writing of the possibility of such
            damage. Because some jurisdictions do not allow limitations on
            implied warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </Text>

          <Text variant="subtitle">5. Accuracy of materials</Text>
          <Text variant="paragraph">
            The materials appearing on Adam Monster&apos;s website could include
            technical, typographical, or photographic errors. Adam Monster does
            not warrant that any of the materials on its website are accurate,
            complete or current. Adam Monster may make changes to the materials
            contained on its website at any time without notice. However Adam
            Monster does not make any commitment to update the materials.
          </Text>

          <Text variant="subtitle">6. Links</Text>
          <Text variant="paragraph">
            Adam Monster has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by Adam Monster of
            the site. Use of any such linked website is at the user&apos;s own
            risk.
          </Text>

          <Text variant="subtitle">7. Modifications</Text>
          <Text variant="paragraph">
            Adam Monster may revise these terms of service for its website at
            any time without notice. By using this website you are agreeing to
            be bound by the then current version of these terms of service.
          </Text>

          <Text variant="subtitle">8. Governing Law</Text>
          <Text variant="paragraph">
            These terms and conditions are governed by and construed in
            accordance with the laws of South Australia and you irrevocably
            submit to the exclusive jurisdiction of the courts in that State or
            location.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default TermsPage
